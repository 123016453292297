<script setup lang="ts">
// https://livechat-jackpotcity.connexone.co.uk/static/documentation/index.html
import { useSiteStore } from '~/stores/site.store';
import { trackingMethods } from '~~/plugins/analytics.client';
import { useAuthStore } from '~/stores/auth.store';
import { liveChatIds } from '~/assets/data/config';

const settings = useSiteStore();
const auth = useAuthStore();

const liveChatInitialised = ref<boolean>(false);
const canToggleLiveChat = ref<boolean>(false);

const emits = defineEmits<{ toggleLiveChat: [toggle: boolean] }>();

const liveChatlink = computed(
  () =>
    `https://livechat-jackpotcity.connexone.co.uk/widget?connid=${
      liveChatIds[settings.getRegionCode]
    }`
);

const launchLiveChat = () => {
  try {
    if (auth.isLoggedIn && !liveChatInitialised.value) {
      window.__CONNEXONE_LIVECHAT__?.authenticateCustomer({
        firstname: auth.currentUser.firstname,
        tel1: auth.currentUser.primaryMobileNumber,
        // country: settings.getCountry.countryIsoTwo,
      });
    }
    window.__CONNEXONE_LIVECHAT__?.openWidget();

    liveChatInitialised.value = true;
  } catch (ex) {
    console.error(ex);
  }
};

const liveChatHooks = () => {
  (window as any).__CONNEXONE_LIVECHAT_HOOKS__ = {
    onLoad() {
      (document.getElementById('cx-nudge') as HTMLElement).style.display =
        'none';
      (document.getElementById('cx-chat') as HTMLElement).style.display =
        'none';
    },
    onWidgetClosed() {
      // send GA tracking method when Chat closed
      trackingMethods.liveChatClosed();

      (document.getElementById('cx-nudge') as HTMLElement).style.display =
        'none';
      (document.getElementById('cx-chat') as HTMLElement).style.display =
        'none';
      settings.explicitToggleFeature({
        feature: 'displayLiveChat',
        toggle: false,
      });
    },
    onWidgetHidden() {
      (document.getElementById('cx-nudge') as HTMLElement).style.display =
        'none';
      (document.getElementById('cx-chat') as HTMLElement).style.display =
        'none';
      settings.explicitToggleFeature({
        feature: 'displayLiveChat',
        toggle: false,
      });
    },
    onWidgetOpened() {
      // send GA tracking method when Chat opened
      trackingMethods.liveChatInit();
      (document.getElementById('cx-nudge') as HTMLElement).style.display =
        'block';
      (document.getElementById('cx-chat') as HTMLElement).style.display =
        'block';
    },
    onWidgetShown() {},
  };
};

onMounted(() => {
  useScript(
    {
      src: liveChatlink.value,
      async: true,
      defer: true,
      attributes: {
        'data-socket-uri': 'https://livechat-jackpotcity.connexone.co.uk',
        id: 'livechat-jackpotcity',
        onload: () => {
          liveChatHooks();
          emits('toggleLiveChat', false);
          canToggleLiveChat.value = true;
        },
      },
    },
    {
      trigger: 'client',
    }
  );
  // useHead({
  //   script: [
  //     {
  //       src: liveChatlink.value,
  //       'data-socket-uri': 'https://livechat-jackpotcity.connexone.co.uk',
  //       id: 'livechat-jackpotcity',
  //       async: true,
  //       defer: true,
  //       onload: () => {
  //         liveChatHooks();
  //         emits('toggleLiveChat', false);
  //         canToggleLiveChat.value = true;
  //       },
  //     },
  //   ],
  // });
  liveChatHooks();
});

watch(
  () => settings.featureDisplay({ feature: 'displayLiveChat' }),
  (value) => {
    if (value) {
      const liveChatEl = document.getElementById('livechat-jackpotcity');
      if (!!liveChatEl) launchLiveChat();
      else {
        emits('toggleLiveChat', true);
        useHead({
          script: [
            {
              src: liveChatlink.value,
              'data-socket-uri': 'https://livechat-jackpotcity.connexone.co.uk',
              id: 'livechat-jackpotcity',
              async: true,
              defer: true,
              onload: () => {
                liveChatHooks();
                emits('toggleLiveChat', false);
                canToggleLiveChat.value = true;
              },
            },
          ],
        });
      }
    }
  }
);

watch(
  () => canToggleLiveChat.value,
  (value) => {
    if (!!value) launchLiveChat();
  }
);

watch(
  () => auth.isLoggedIn,
  (value) => {
    if (!value) {
      window?.__CONNEXONE_LIVECHAT__?.closeWidget();
      liveChatInitialised.value = false;
      settings.explicitToggleFeature({
        feature: 'displayLiveChat',
        toggle: false,
      });
      useCookie(
        `cnx_cx_livechat-jackpotcity${liveChatIds[settings.getRegionCode]}`
      ).value = null;
      useCookie(
        `cnx_pp_livechat-jackpotcity${liveChatIds[settings.getRegionCode]}`
      ).value = null;
    }
    const connexContainerEl = document?.querySelector(
      '.cx-webChat-publicIframe'
    );
    if (!!connexContainerEl && !!connexContainerEl?.children) {
      connexContainerEl.children[0].src += '';
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>
<template><div></div></template>
